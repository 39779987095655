<template>
    <div ref="sizerPanel" class="sizerpanel">
        <div class="height-sizer" v-if="height!==undefined" ref="heightSizer" :class="sizerClass">
            <span v-if="!hideIcons && (collapse !== undefined || expand !== undefined)" class="sizer-buttons" :class="sizerButtonsContainerClass">
                <div class="icons-cover d-flex flex-row justify-content-center align-items-center">
                    <i class="bi-chevron-up" :class="{'d-none' : !showUpIcon }"></i>
                    <i class="bi-chevron-down" :class="{'d-none' : !showDownIcon }"></i>
                </div>
                <i class="'bi bi-chevron-up sizer-up-icon flex-1" :class="{'d-none' : !showUpIcon }" @click="collapseTop($event)"
                    :title="$t('Collapse')"></i>
                <i class="'bi bi-chevron-down sizer-down-icon flex-1" :class="{'d-none' : !showDownIcon }" @click="collapseBottom($event)"
                    :title="$t('Collapse')"></i>
            </span>
        </div>
        <div class="width-sizer" v-if="width!==undefined" ref="widthSizer" :class="sizerClass">
            <span v-if="!hideIcons && (collapse !== undefined || expand !== undefined)" class="sizer-buttons" :class="sizerButtonsContainerClass">
                <div class="icons-cover d-flex flex-column justify-content-center align-items-center">
                    <i class="bi-chevron-left" :class="{'d-none' : !showLeftIcon }"></i>
                    <i class="bi-chevron-right" :class="{'d-none' : !showRightIcon }" ></i>
                </div>
                <i class="'bi bi-chevron-left sizer-left-icon flex-1" :class="{'d-none' : !showLeftIcon }" @click="collapseLeft($event)"
                    :title="$t('Collapse')"></i>
                <i class="'bi bi-chevron-right sizer-right-icon flex-1" :class="{'d-none' : !showRightIcon }" @click="collapseRight($event)"
                    :title="$t('Collapse')"></i>
            </span>
        </div>
        <slot></slot>
    </div>
</template>    

<script setup>
import { ref, useAttrs, onMounted, nextTick, inject} from 'vue';
import { InjectionKeys } from 'o365-utils';
import { app } from 'o365-modules';
import Sizer from './Sizer.Sizer.ts';
import { useEventListener } from 'o365-vue-utils';

const props = defineProps({
    collapse: {
        type: String,
        default: undefined
    },
    expand: {
        type: String,
        default: undefined
    },
    width: {
        type: String,
        default: undefined
    },
    height: {
        type: String,
        default: undefined
    },
    noContainerClass: {
        type: Boolean,
        default: false
    },
    hideIcons: {
        type: Boolean,
        default: false
    },
    /** Enable EventEmitter on the Sizer control for Resized and Resizing events */
    useEvents: {
        type: Boolean,
        default: false
    },
    /** Set the panel as collapsed initially (must have collapse prop) */
    initiallyCollapsed: {
        type: Boolean,
        default: false,
    },
    /** Set the panel as expanded initially (must have expand prop) */
    initiallyExpanded: {
        type: Boolean,
        default: false,
    },
    /** Sets the max width of the sizer panel. Value can be either in pixels or percentage. Ex: "600px" or "20%" */
    maxWidth: {
        type: String,
        default: null
    },
    /** Sets the max height of the sizer panel. Value can be either in pixels or percentage. Ex: "600px" or "20%" */
    maxHeight: {
        type: String,
        default: null
    },    
    /** Sets the min width of the sizer panel. Value can be either in pixels or percentage. Ex: "600px" or "20%" */
    minWidth: {
        type: String,
        default: null
    },
    /** Sets the min height of the sizer panel. Value can be either in pixels or percentage. Ex: "600px" or "20%" */
    minHeight: {
        type: String,
        default: null
    },
    /** Auto collapse when viewport is smaller than this value */
    collapseAt: {
        type: String,
    },
    skipLocalStorage: Boolean,
    sizerClass: null,
    fixedSibling: Boolean,
});

if (props.initiallyCollapsed && props.initiallyExpanded) {
    console.warn('OSizerPanel should not have both initiallyCollapsed and initiallyExpanded enabled at the same time');
}

/**
 * For 'resizing' and 'resized' events to fire 'useEvents' must be provided 
 */
const emit = defineEmits(['collapsed', 'resizing', 'resized']);

const attrs = useAttrs();

const sizerPanel = ref(null);
const widthSizer = ref(null);
const heightSizer = ref(null);

const leftIcon = ref(null);
const rightIcon = ref(null);
const topIcon = ref(null);
const bottomIcon = ref(null);

const leftIcon2 = ref(null);
const rightIcon2 = ref(null);
const topIcon2 = ref(null);
const bottomIcon2 = ref(null);

const showLeftIcon = ref(false);
const showRightIcon = ref(false);
const showUpIcon = ref(false);
const showDownIcon = ref(false);

let sizerControl = null;
const sizerButtonsContainerClass = ref(null);

//** Promise provieded by our bootstrap controls for awaiting transitions */
const bootstrapTransitionPromise = inject(InjectionKeys.bootstrapTransitionPromiseKey, null);

function setIcons(icons) {
    switch(icons) {
        case "left":
            sizerButtonsContainerClass.value = 'hover-expand-left';
            showLeftIcon.value = true;
            showRightIcon.value = false;
            break;
        case "right":
            sizerButtonsContainerClass.value = 'hover-expand-right';
            showRightIcon.value = true;
            showLeftIcon.value = false;
            break;
        case "horizontal":
            sizerButtonsContainerClass.value = 'hover-expand-horizontal';
            showLeftIcon.value = true;
            showRightIcon.value = true;
            break;
        case "top":
            sizerButtonsContainerClass.value = 'hover-expand-up';
            showUpIcon.value = true;
            showDownIcon.value = false;
            break;
        case "bottom":
            sizerButtonsContainerClass.value = 'hover-expand-down';
            showDownIcon.value = true;
            showUpIcon.value = false;
            break;
        case "vertical":
            sizerButtonsContainerClass.value = 'hover-expand-vertical';
            showUpIcon.value = true;
            showDownIcon.value = true;
            break;
        default:
            break;
    }
}

function handleWindowResize() {
    const windowWidth = window.innerWidth;
    const collapseAtWidth = parseInt(props.collapseAt);
    if (windowWidth <= collapseAtWidth) {
        sizerControl.attemptAutoCollapse();
    } else {
        sizerControl.attemptAutoExpand();
    }
}

let windowResizeDebounce = null;
if (props.collapseAt) {
    useEventListener(window, 'resize', () => {
        if (windowResizeDebounce) { window.clearTimeout(windowResizeDebounce); }
        windowResizeDebounce = window.setTimeout(() => {
            handleWindowResize(); 
            windowResizeDebounce = null;
        }, 10);
    }, { passive: true});
}

onMounted(() => {
    sizerPanel.value.style.width = props.width;
    sizerPanel.value.style.height = props.height;
    let vItems, vId, vDirection;

    vItems = document.getElementsByClassName("sizerpanel")
    const keyPrefix = app.id + '_sizerPanel_';
    // Do not include search params in key
    if (attrs.id != null) {
        //vId = window.location.href + "_sizerPanel_" + attrs.id;
        vId = keyPrefix + attrs.id;
    } else {
        for (var item in vItems) {
            if (vItems[item] === sizerPanel.value) {
                vId = keyPrefix + item;
                //vId = window.location.href + "_sizerPanel_" + item
            }
        }
    }

    if (props.width) {
        if (!props.noContainerClass) {
            sizerPanel.value.classList.add("row-container");
        }

        if (props.collapse !== undefined && sizerPanel.value.nextElementSibling) {
            //console.log('condition 1');
            //leftIcon.value.style.display = "inline";
            showLeftIcon.value = true;
            vDirection = "left";
        } else if (props.collapse !== undefined && !sizerPanel.value.nextElementSibling) {
            //console.log('condition 2');
            //rightIcon.value.style.display = "inline";
            showRightIcon.value = true;
            vDirection = 'right';
        }
        if (props.expand !== undefined && sizerPanel.value.nextElementSibling) {
            //console.log('condition 3');
            showRightIcon.value = true;
            if (!vDirection) { vDirection = 'left'; }
            //rightIcon.value.style.display = "inline";
        } else if (props.expand !== undefined && !sizerPanel.value.nextElementSibling) {
            //console.log('condition 4');
            showLeftIcon.value = true;
            if (!vDirection) { vDirection = 'right'; }
            //leftIcon.value.style.display = "inline";
        }

        if (props.collapse === undefined && props.expand === undefined) {
            if (sizerPanel.value.nextElementSibling) {
                vDirection = 'left';
            } else {
                vDirection = 'right';
            }
        }

        if (showLeftIcon.value && showRightIcon.value) {
            sizerButtonsContainerClass.value = 'hover-expand-horizontal';
        } else if (showLeftIcon.value) {
            sizerButtonsContainerClass.value = 'hover-expand-left';
        } else if (showRightIcon.value) {
            sizerButtonsContainerClass.value = 'hover-expand-right';
        }
    } else {
        if (!props.noContainerClass) {
            sizerPanel.value.classList.add("col-container");
        }
        if (props.collapse !== undefined && sizerPanel.value.nextElementSibling) {
            //topIcon.value.style.display = "inline";
            showUpIcon.value = true;
            vDirection = "top";
        } else if (props.collapse !== undefined && !sizerPanel.value.nextElementSibling) {
            //bottomIcon.value.style.display = "inline";
            showDownIcon.value = true;
            vDirection = "bottom";
        }

        if (props.expand !== undefined && sizerPanel.value.nextElementSibling) {
            //bottomIcon.value.style.display = "inline";
            showDownIcon.value = true;
            if (!vDirection) { vDirection = 'top'; }
        } else if (props.expand !== undefined && !sizerPanel.value.nextElementSibling) {
            //topIcon.value.style.display = "inline";
            showUpIcon.value = true;
            if (!vDirection) { vDirection = 'bottom'; }
        }

        if (props.collapse === undefined && props.expand === undefined) {
            if (sizerPanel.value.nextElementSibling) {
                vDirection = 'top';
            } else {
                vDirection = 'bottom';
            }
        }

        if (showUpIcon.value && showDownIcon.value) {
            sizerButtonsContainerClass.value = 'hover-expand-vertical';
        } else if (showUpIcon.value) {
            sizerButtonsContainerClass.value = 'hover-expand-up';
        } else if (showDownIcon.value) {
            sizerButtonsContainerClass.value = 'hover-expand-down';
        }
    }

    sizerControl = new Sizer(sizerPanel.value, {
        horizontal: (props.width ? false : true),
        id: vId,
        collapse: props.collapse === undefined ? false : true,
        expand: props.expand === undefined ? false : true,
        initiallyCollapsed: props.initiallyCollapsed,
        initiallyExpanded: props.initiallyExpanded,
        direction: vDirection,
        setIconsFn: setIcons,
        maxWidth: props.maxWidth,
        maxHeight: props.maxHeight,
        minWidth: props.minWidth,
        minHeight: props.minHeight,
        bootstrapTransitionPromise: bootstrapTransitionPromise,
        collapseAt: props.collapseAt
    }, props, emit);

    nextTick().then(() => {
        // Clear h-100 and w-100 from sizing containers
        sizerControl.clearContainerClasses();
        if (props.collapseAt) {
            handleWindowResize()
        }
    });
});

function getSizer() { return sizerControl; }

function collapseRight(e) {
    sizerControl.right();
    console.log(e)
    emit('collapsed', 'right');
}

function collapseLeft(e) {
    sizerControl.left();
    console.log(e)
    emit('collapsed', 'left');
}

function collapseBottom(e) {
    sizerControl.bottom()
    emit('collapsed', 'bottom');
}

function collapseTop(e) {
    sizerControl.top()
    emit('collapsed', 'top');
}

defineExpose({ getSizer, sizerPanel });

</script>   

<style>
    .width-sizer-animate {
        transition: width 0.3s ease;
    }
</style>

<style scoped>
.width-sizer {
    height: 100%;
    cursor: e-resize;
    width: 7px;
    position: absolute;
    background-color: var(--bs-border-color);
    z-index: 999;
    touch-action: none;
}

.height-sizer {
    width: 100%;
    cursor: n-resize;
    height: 7px;
    position: absolute;
    background-color: var(--bs-border-color);
    z-index: 998;
    touch-action: none;
}

.sizerpanel {
    position: relative;
}

.width-sizer-animate {
    transition: margin-right 0.3s ease, margin-left 0.3s ease;
}

.height-sizer-animate {
    transition: margin-top 0.3s ease, margin-bottom 0.3s ease;
}


/* Sizer buttons container */
.sizer-buttons {
    z-index: 5;
    position: absolute;
    display: flex;
    background-color: var(--bs-link-color);
    overflow: hidden;
}

/* width-sizer buttons container */
.width-sizer .sizer-buttons {
    top: calc(50% - 5px);
    width: 9px;
    height: 30px;
    left: -1px;
    transition: width 0.1s ease-in-out, transform 0.1s ease-in-out, border-radius 0.1s ease-in-out;
}
/* height-sizer buttons container */
.height-sizer .sizer-buttons {
    display: flex;
    flex-direction: column;

    left: calc(50% - 15px);
    height: 9px;
    width: 30px;
    top: -1px;
    transition: height 0.1s ease-in-out, transform 0.1s ease-in-out, border-radius 0.1s ease-in-out;
}

/* expand buttons container left and right */
.width-sizer:not(.collapsedLeft):not(.collapsedRight) .sizer-buttons.hover-expand-horizontal:hover {
    width: 59px;
    border-radius: 24px;
    transform: translateX(-26px);
}
/* expand buttons container up and down */
.height-sizer:not(.collapsedTop):not(.collapsedBottom) .sizer-buttons.hover-expand-vertical:hover {
    height: 59px;
    border-radius: 24px;
    transform: translateY(-26px);
}

/* expand buttons container left */
.sizer-buttons.hover-expand-left:hover, .width-sizer.collapsedRight .sizer-buttons {
    width: 33px;
    border-radius: 24px 0px 0px 24px;
    transform: translateX(-26px);
}
/* expand buttons container up */
.sizer-buttons.hover-expand-up:hover, .height-sizer.collapsedBottom .sizer-buttons {
    height: 33px;
    border-radius: 24px 24px 0px 0px;
    transform: translateY(-26px);
}

/* expand buttons container right */
.sizer-buttons.hover-expand-right:hover, .width-sizer.collapsedLeft .sizer-buttons {
    width: 33px;
    border-radius: 0px 24px 24px 0px;
}
/* expand buttons container down */
.sizer-buttons.hover-expand-down:hover, .height-sizer.collapsedTop .sizer-buttons {
    height: 33px;
    border-radius: 0px 0px 24px 24px;
}

/* left icon */
.sizer-left-icon {
    display: none;
    color: white;

    border-radius: 24px 0px 0px 24px;
    height: 30px;
}
/* up icon */
.sizer-up-icon {
    display: none;
    color: white;

    border-radius: 24px 24px 0px 0px;
    width: 30px;
    height: 50%;
}

/* left icon hover */
.sizer-left-icon:hover {
    background-color: var(--bs-link-hover-color);
}
/* up icon hover */
.sizer-up-icon:hover {
    background-color: var(--bs-link-hover-color);
}

/* left icon positioning */
.sizer-left-icon::before {
    position: absolute;
    top: 6px;
    left: 4px;
}
/* up icon positioning */
.sizer-up-icon::before {
    position: absolute;
    top: 5px;
    left: 7px;
}

/* right icon */
.sizer-right-icon {
    display: none;
    color: white;

    float: right;
    border-radius: 0px 24px 24px 0px;
    height: 30px;
}
/* down icon */
.sizer-down-icon {
    display: none;
    color: white;

    border-radius: 0px 0px 24px 24px;
    height: 50%;
    width: 30px;
}

/* right icon hover */
.sizer-right-icon:hover {
    background-color: var(--bs-link-hover-color);
}
/* down icon hover */
.sizer-down-icon:hover {
    background-color: var(--bs-link-hover-color);
}

/* right icon position */
.sizer-right-icon::before {
    position: absolute;
    top: 6px;
    right: 4px;
}
/* down icon position */
.sizer-down-icon::before {
    position: absolute;
    bottom: 5px;
    left: 7px;
}

/* show left icon */
.width-sizer:not(.collapsedLeft) .sizer-buttons:hover .sizer-left-icon,
.collapsedRight .sizer-buttons .sizer-left-icon {
    display: block;
    cursor: pointer;
}
/* show up icon */
.height-sizer:not(.collapsedTop) .sizer-buttons:hover .sizer-up-icon,
.collapsedBottom .sizer-buttons .sizer-up-icon {
    display: block;
    cursor: pointer;
}

/* show right icon */
.width-sizer:not(.collapsedRight) .sizer-buttons:hover .sizer-right-icon,
.collapsedLeft .sizer-buttons .sizer-right-icon {
    display: block;
    cursor: pointer;
}
/* show down icon */
.height-sizer:not(.collapsedBottom) .sizer-buttons:hover .sizer-down-icon,
.collapsedTop .sizer-buttons .sizer-down-icon {
    display: block;
    cursor: pointer;
}

/* "cover" icons container */
.width-sizer .icons-cover {
    color: white;
    width: 100%;
    height: 28px;
    margin-top: -1px;
}
/* height "cover" icons container */
.height-sizer .icons-cover {
    color: white;
    width: 28px;
    height: 100%;
    margin-left: 2px;
}

/* width "cover" icons sizing */
.width-sizer .icons-cover i {
    height: 50%;
    font-size: 0.75rem;
}
/* height "cover" icons sizing */
.height-sizer .icons-cover i {
    width: 50%;
    font-size: 0.75rem;
}

/* sizer bar highlight on hover and drag */
.width-sizer:hover, .height-sizer:hover, .pointer-events .width-sizer, .pointer-events .height-sizer {
    background-color: var(--o365-sizer-hover)
}

/* hide "cover" icons on hover */
.sizer-buttons:hover .icons-cover,
.collapsedTop .sizer-buttons .icons-cover,
.collapsedBottom .sizer-buttons .icons-cover,
.collapsedLeft .sizer-buttons .icons-cover,
.collapsedRight .sizer-buttons .icons-cover {
    display: none !important;
}
</style>